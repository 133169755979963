/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";

import { Heading as RebassHeading } from "rebass";

interface Props {
  className?: string;
  id?: string;
  renderedAs?: "h1" | "h2" | "h3";
  variant?: "title" | "heading" | "subheading";
  children: any;
  css?: any;
}

const Heading: FC<Props & JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  renderedAs = "h2",
  variant="heading",
  children,
  ...rest
}) => {
  return (
    <RebassHeading
      as={renderedAs}
      variant={variant}
      className={className}
      id={id}
    >
      {children}
    </RebassHeading>
  );
};

export default Heading;
