/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, ReactNode } from 'react';
import { Flex, Text, Box, Link } from 'rebass';
import MainContainer from '../container/container';
// @ts-ignore
import Mark from '../../assets/svgs/mark.svg';

interface Props {
  className?: string;
  id?: string;
  children?: ReactNode;

  css?: any;
}

const Nav: FC<Props & JSX.IntrinsicElements['nav']> = ({
  className = '',
  id = '',
  children,
  ...rest
}) => {
  return (
    <Box backgroundColor="#403F4C" py={[2, 4]} px={[0, 4]}>
      <Flex as="footer">
        <MainContainer
          py={4}
          sx={{}}
          justifyContent="space-between"
          alignItems="start"
        >
          <Flex
            flex={['0 1 50%', '0 1 50%', '0 1 30%']}
            maxWidth={['50%', '50%', '30%']}
            flexWrap="wrap"
          >
            <Link variant="footerHeader" href="#">
              Services
            </Link>
            <Link variant="footer" href="/tax-planning-and-preparation">
              Tax Planning and Preparation
            </Link>
            <Link variant="footer" href="/accounting">
              Accounting
            </Link>
            <Link variant="footer" href="/business-set-up">
              Business Set Up
            </Link>
            <Link variant="footer" href="/financial-planning">
              Financial Planning
            </Link>
          </Flex>
          <Flex
            flex={['0 1 50%', '0 1 50%', '0 1 30%']}
            maxWidth={['50%', '50%', '30%']}
            flexWrap="wrap"
          >
            <Link variant="footerHeader" href="#">
              Useful Links
            </Link>
            <Link
              variant="footer"
              href="https://www.ptindirectory.com/tax-preparers/california/upland-ca/1439322/knecht-income-tax-service-llc/john-t-knecht"
            >
              PTIN
            </Link>
            <Link
              variant="footer"
              href="https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp"
            >
              IRS Where's My Refund
            </Link>
            <Link
              variant="footer"
              href="https://www.irs.gov/filing/wheres-my-amended-return"
            >
              IRS Amended Refund Status
            </Link>

            <Link
              variant="footer"
              href="https://www.irs.gov/coronavirus/get-my-payment"
            >
              Stimulus Payment Status
            </Link>
            <Link
              variant="footer"
              href="https://www.irs.gov/coronavirus-tax-relief-and-economic-impact-payments"
            >
              IRS COVID Programs
            </Link>
          </Flex>
          <Flex
            flex={['0 1 50%', '0 1 50%', '0 1 30%']}
            maxWidth={['50%', '50%', '30%']}
            flexWrap="wrap"
          >
            <Link variant="footerHeader" href="#">
              Legal
            </Link>
            <Link variant="footer" href="/privacy">
              Privacy Policy
            </Link>
            <Link variant="footer" href="/terms-of-service">
              Terms of Service
            </Link>
            <Link variant="footer" href="/cookie-policy">
              Cookie Policy
            </Link>
          </Flex>
          <Text
            fontSize="11px"
            fontWeight="bold"
            flex="0 1 100%"
            maxWidth="100%"
            color="white"
            mt={3}
          >
            <span role="img" aria-label="Copyright">
              &copy;
            </span>
            {` ${new Date().getFullYear()} Knecht Income Tax Service, LLC`}
          </Text>
        </MainContainer>
      </Flex>
    </Box>
  );
};

export default Nav;
