import * as Yup from 'yup';

const INITIALVALUES = {
    name: "",
    phone: "",
    call: false,
    text: false,
    personal: false,
    business: false,
    taxes: false,
    bookkeeping: false,
    financialPlanning: false,
    businessSetUp: false,
    comment: ""
  };

const VALIDATIONSCHEMEA = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Name is required"),
    phone: Yup.string().matches(
      /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
      {
        message: "Oops, that's not a phone number."
      }
    ),
    call: Yup.bool(),
    text: Yup.bool(),
    personal: Yup.bool(),
    business: Yup.bool(),
    taxes: Yup.bool(),
    bookkeeping: Yup.bool(),
    financialPlanning: Yup.bool(),
    businessSetUp: Yup.bool(),
    comment: Yup.string()
  });

export { INITIALVALUES, VALIDATIONSCHEMEA } ;