/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, ReactNode, useState } from 'react';
import { Flex, Text, Box, Link, Image } from 'rebass';

interface NavItem {
  slug?: string;
  text?: string;
  id: string;
}

interface Props {
  className?: string;
  htmlId?: string;
  children?: ReactNode;
  css?: any;
  navItem?: NavItem;
  isDropdownCategory?: boolean;
  dropdownParent?: boolean;
  dropdownChildren?: Array<NavItem>;
}

interface DropdownProps {
  dropdownOpen: boolean;
  dropdownChildren: Array<NavItem>;
}

const Dropdown: FC<DropdownProps> = ({ dropdownOpen, dropdownChildren }) => (
  <Flex
    flexDirection="column"
    px={1}
    pb={3}
    bg="ourWhite"
    sx={{
      position: 'absolute',
      pt: '29px',
      width: `calc(100% + 40px)`,
      left: '-4px',
      zIndex: 99,
      '&::before': {
        content: "''",
        position: 'relative',
        top: '-13px',
        left: '-4px',
        width: 'calc(100% + 8px)',
        height: '2px',
        backgroundColor: 'secondary'
      }
    }}
    className={`${dropdownOpen ? '' : 'hidden'}`}
  >
    {dropdownChildren.map(({ slug, text, id }) => (
      <Link key={id} variant="nav" href={slug} my={2} data-js-gtm-link-track={`${text}-${slug}`}>
        {text}
      </Link>
    ))}
  </Flex>
);

const NavbarItem: FC<Props> = ({
  className = '',
  htmlId,
  children,
  navItem: { slug, text, id },
  isDropdownCategory,
  dropdownParent,
  dropdownChildren,
  ...rest
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <Text variant="nav" key={id}>
      <Box sx={{ position: 'relative' }}>
        {slug && !dropdownParent && (
          <Box
            {...(isDropdownCategory && {
              onMouseEnter: () => setDropdownOpen(true),
              onMouseLeave: () => setDropdownOpen(false),
              onTouchStart: () => setDropdownOpen(true),
              onTouchEnd: () => setDropdownOpen(false)
            })}
          >
            <Link variant="nav" href={slug} data-js-gtm-link-track={`${text}-${slug}`}>
              {text}
            </Link>
            {dropdownChildren.length > 0 && (
              <Dropdown
                dropdownChildren={dropdownChildren}
                dropdownOpen={dropdownOpen}
              />
            )}
          </Box>
        )}
        {!slug && (
          <Text
            variant="nav"
            sx={{ color: 'secondary', mx: 3 }}
            {...(isDropdownCategory && {
              onMouseEnter: () => setDropdownOpen(true),
              onMouseLeave: () => setDropdownOpen(false),
              onTouchStart: () => setDropdownOpen(true),
              onTouchEnd: () => setDropdownOpen(false)
            })}
          >
            {text}
            {dropdownChildren.length > 0 && (
              <Dropdown
                dropdownChildren={dropdownChildren}
                dropdownOpen={dropdownOpen}
              />
            )}
          </Text>
        )}
      </Box>
    </Text>
  );
};

export default NavbarItem;
