/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
import Markdown from 'react-markdown';

import { Flex, Box, Heading } from 'rebass';
import MainContainer from '../../ui-components/container/container';
import FormModal from '../form/modal';
import BackgroundImage from 'gatsby-background-image';

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle: {
      subtitle: string;
    };
    image: any;
    color: boolean;
    hideButton: boolean;
  };
  titleAs?: 'h1' | 'h2';
  subtitleAs?: 'h1' | 'h2';
  css?: any;
}

export const query = graphql`
  fragment FullScreenImageSection on ContentfulFullScreenImageSection {
    title
    subtitle {
      subtitle
    }
    image {
      ...ContentfulAsset
    }
    color
    hideButton
    internal {
      type
    }
  }
`;

const FullImageFoundation: FC<Props & JSX.IntrinsicElements['div']> = ({
  className = '',
  id = '',
  titleAs = 'h1',
  subtitleAs = 'h2',
  data,
  ...rest
}) => {
  const {
    title,
    subtitle: { subtitle },
    image,
    color,
    hideButton
  } = data;

  return (
    <BackgroundImage fluid={image.fluid} className="image__full-screen">
      <Flex height="500px" alignItems="center">
        <MainContainer margin="auto" color={color ? 'white' : 'black'}>
          <Box width="100%">
            <Heading as="h1" variant="heading">
              {title}
            </Heading>
          </Box>
          <Box width="100%">{subtitle && <Markdown source={subtitle} />}</Box>
          {!hideButton && <FormModal />}
        </MainContainer>
      </Flex>
    </BackgroundImage>
  );
};

export default FullImageFoundation;
