/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, useState, createRef } from 'react';
import { graphql } from 'gatsby';
import { Flex, Box, Button, Text } from 'rebass';
import { navigate } from 'gatsby';
import { useForm, Controller } from 'react-hook-form';

import {
  Label,
  Input as RebassInput,
  Checkbox as RebassCheckbox,
  Textarea as RebassTextarea
} from '@rebass/forms';

import Heading from '../../ui-components/heading/heading';
import { VALIDATIONSCHEMEA, INITIALVALUES } from './constants';

export interface FormContentProps {
  title: string;
  buttonText: string;
}
interface Props {
  className?: string;
  id?: string;
  modalClick?: any;
  css?: any;
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const FormWrapper: FC<Props & JSX.IntrinsicElements['div']> = ({
  // data: { title, buttonText },
  modalClick
}) => {
  const { register, handleSubmit, errors, control, reset } = useForm({
    validationSchema: VALIDATIONSCHEMEA,
    defaultValues: INITIALVALUES
  });

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');
  const [call, setCall] = useState(false);
  const [text, setText] = useState(false);
  const [business, setBusiness] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [taxes, setTaxes] = useState(false);
  const [bookkeeping, setBookkeeping] = useState(false);
  const [businessSetUp, setBusinessSetUp] = useState(false);
  const [financialPlanning, setFinancialPlanning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values, e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'leads',
        ...values
      })
    })
      .then(() => {
        setIsSubmitting(false);
        navigate(
          document.getElementById('js-netlify-form').getAttribute('action')
        );
      })
      .catch(error => {
        alert('Error: Please Try Again!');
        setIsSubmitting(false);
      });
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="baseline">
        <Heading as="h4" variant="formTitle">
          What can we help with?
        </Heading>
        {modalClick && (
          <Button
            sx={{
              right: 0,
              border: 0,
              bg: 'transparent',
              color: 'primary',
              padding: 0
            }}
            variant="primaryButton"
            onClick={modalClick}
            data-js-gtm-button-track={`form-exit`}
          >
            x
          </Button>
        )}
      </Flex>

      <Box
        as="form"
        className="form"
        name="leads"
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
        action="/thanks"
        data-netlify="true"
        netlify-honeypot="bot-field"
        sx={{
          maxWidth: '350px'
        }}
        id="js-netlify-form"
      >
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <Flex
          flex={['0 1 100%']}
          maxWidth={['100%']}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label htmlFor={name}>Name</Label>
            <Controller
              as={RebassInput}
              id="name"
              name="name"
              aria-invalid={errors.name ? 'true' : 'false'}
              aria-describedby="nameError"
              control={control}
              fontSize={3}
            />
            {errors.name && <Text id="nameError">{errors.name.message}</Text>}
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label htmlFor={phone}>Phone</Label>
            <Controller
              as={RebassInput}
              id="phone"
              name="phone"
              aria-invalid={errors.phone ? 'true' : 'false'}
              aria-describedby="phoneError"
              control={control}
              fontSize={3}
            />
            {errors.phone && (
              <Text id="phoneError">{errors.phone.message}</Text>
            )}
          </Box>
          <Box flex={['0 1 100%']} maxWidth={['100%']} my={1}>
            Contact preference:
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="call"
                id="call"
                value={call}
                onChange={() => setCall(true)}
                disabled={isSubmitting}
                ref={register}
                fontSize={[1]}
              />
              Call
            </Label>
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="text"
                id="text"
                value={text}
                onChange={() => setText(true)}
                disabled={isSubmitting}
                ref={register}
                defaultChecked
                fontSize={[1]}
              />
              Text
            </Label>
          </Box>
          <Box flex={['0 1 100%']} maxWidth={['100%']} my={1}>
            Is this for business or personal?
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="business"
                id="business"
                value={business}
                onChange={() => setBusiness(!business)}
                disabled={isSubmitting}
                ref={register}
                fontSize={[1]}
              />
              Business
            </Label>
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="personal"
                id="personal"
                value={personal}
                onChange={() => setPersonal(!personal)}
                disabled={isSubmitting}
                ref={register}
                defaultChecked
                fontSize={[1]}
              />
              Personal
            </Label>
          </Box>
          <Box flex={['0 1 100%']} maxWidth={['100%']} my={1}>
            What services are you interested in?
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="taxes"
                id="taxes"
                value={taxes}
                onChange={() => setTaxes(!taxes)}
                disabled={isSubmitting}
                ref={register}
                fontSize={[1]}
              />
              Taxes
            </Label>
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="bookkeeping"
                id="bookkeeping"
                value={bookkeeping}
                onChange={() => setBookkeeping(!bookkeeping)}
                disabled={isSubmitting}
                ref={register}
                defaultChecked
                fontSize={[1]}
              />
              Bookkeeping
            </Label>
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="businessSetUp"
                id="businessSetUp"
                value={businessSetUp}
                onChange={() => setBusinessSetUp(!businessSetUp)}
                disabled={isSubmitting}
                ref={register}
                fontSize={[1]}
              />
              Business Set Up
            </Label>
          </Box>
          <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
            <Label>
              <RebassCheckbox
                name="financialPlanning"
                id="financialPlanning"
                value={financialPlanning}
                onChange={() => setFinancialPlanning(!financialPlanning)}
                disabled={isSubmitting}
                ref={register}
                defaultChecked
                fontSize={[1]}
              />
              Financial Planning
            </Label>
          </Box>
        </Flex>
        <Flex flexWrap="wrap" my={1}>
          <Box width={1} my={1}>
            <Controller
              as={RebassTextarea}
              id="comment"
              name="comment"
              placeholder="What concerns do you have?"
              control={control}
            />
          </Box>
        </Flex>
        <Box className="hidden">
          <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
        </Box>
        <Flex justifyContent="space-between">
          <Button
            name="submit"
            type="submit"
            disabled={isSubmitting}
            className="button"
            variant="primaryButton"
            my={2}
            data-js-gtm-button-track={'form-submission'}
          >
            Submit
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormWrapper;
