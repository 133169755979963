/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, useState, useEffect, useCallback } from 'react';
import { Box, Button, Flex } from 'rebass';
import { graphql } from 'gatsby';
import Form from './form';
import Modal from 'react-modal';

interface Props {
  className?: string;
  id?: string;
  css?: any;
}

const FormModal = data => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Box>
      <Button variant="primary" onClick={() => setModalIsOpen(!modalIsOpen)} data-js-gtm-button-track={`form-trigger`}>
        Schedule a chat
      </Button>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Form Modal"
      >
        <Form modalClick={() => setModalIsOpen(!modalIsOpen)} />
      </Modal>
    </Box>
  );
};

export default FormModal;
