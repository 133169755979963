import React, { useState, useLayoutEffect } from 'react';
import Transition from 'react-transition-group/Transition';
import { Flex, Text, Box, Link, Button, Image } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import HamburgerIcon from './components/hamburger-icon/hamburger-icon';
import Logo from '../../assets/logo.png';

const MobileNavbar = assignedColor => {
  const {
    contentfulMenu: { linkList }
  } = useStaticQuery(
    graphql`
      query {
        contentfulMenu {
          linkList {
            id
            text
            slug
            isDropdownCategory
            dropdownParent {
              id
              text
              slug
            }
          }
        }
      }
    `
  );

  const toggleBodyScroll = () => {
    !menuOpen
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'unset');
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    toggleBodyScroll();
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    toggleBodyScroll();
    setMenuOpen(false);
  };

  const handleCloseButtonClick = () => {
    toggleBodyScroll();
    setMenuOpen(false);
  };

  const lightHeader = assignedColor.color === 'white' && !menuOpen;

  // establish parent -> child relationship for easier iteration. Leave child -> parent in CMS as that is easier to edit when creating new links.
  linkList.map(link => {
    link.children = [];
    if (link.dropdownParent) {
      const parentItem = linkList.find(
        item => item.id === link.dropdownParent.id
      );
      parentItem.children.push(link);
    }
  });

  return (
    <Box>
      <Box
        as="nav"
        variant={menuOpen ? 'mobileMenuOpened' : 'mobileMenuClosed'}
        bg="ourWhite"
      >
        <Flex>
          <Link my="auto" href="/" display="flex" sx={{ alignItems: 'center' }}>
            <Image
              src={Logo}
              alt="BootUp Professional Development Logo"
              sx={{ height: '60px' }}
            />
          </Link>
          <Box ml="auto" my="auto">
            <HamburgerIcon
              open={menuOpen}
              onClick={handleHamburgerClick}
              color={lightHeader ? 'ourWhite' : 'text'}
            />
          </Box>
        </Flex>
      </Box>

      <Transition in={menuOpen} timeout={150} mountOnEnter unmountOnExit>
        <Box
          bg="ourWhite"
          height="100%"
          left="0"
          overflow="scroll"
          p="88px 24px 0"
          width="100%"
          sx={{
            top: '0',
            transition: 'opacity 0.15s, transform 0.15s',
            position: 'fixed',
            willChange: 'transform, opacity',
            zIndex: 10
          }}
        >
          <Box display={menuOpen ? 'block' : 'none'} pt={3}>
            {linkList.map(({ id, slug, text, dropdownParent, children }) => {
              const isParent = children && children.length > 0;
              const isChild = dropdownParent;
              let rendered = null;

              if (!isParent && !isChild) {
                rendered = (
                  <Text variant="caption" key={id || slug || text}>
                    <Link
                      variant="mobileNav"
                      href={slug}
                      onClick={handleLinkClick}
                    >
                      {text}
                    </Link>
                  </Text>
                );
              } else if (isParent && !isChild) {
                rendered = (
                  <Accordion key={id || slug || text} allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <Text variant="caption">
                            <Box variant="mobileNav">{text}</Box>
                          </Text>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Text variant="caption" key={id || slug || text}>
                          <Link
                            variant="mobileNav"
                            href={slug}
                            onClick={handleLinkClick}
                            ml={4}
                          >
                            {text}
                          </Link>
                        </Text>
                        {children &&
                          children.map(({ id, slug, text }) => (
                            <Text variant="caption" key={id || slug || text}>
                              <Link
                                variant="mobileNav"
                                href={slug}
                                onClick={handleLinkClick}
                                ml={4}
                              >
                                {text}
                              </Link>
                            </Text>
                          ))}
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                );
              }

              return rendered;
            })}
          </Box>
        </Box>
      </Transition>

      <Transition in={menuOpen} timeout={150} unmountOnExit mountOnEnter>
        <Button
          type="button"
          varient="closeButtonBottom"
          onClick={handleCloseButtonClick}
        >
          &times;
        </Button>
      </Transition>
    </Box>
  );
};

export default MobileNavbar;
