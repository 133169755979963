import React from 'react';
import { Helmet } from 'react-helmet';
import { Styled } from 'theme-ui';

import Navbar from './navbar/navbar';
import Footer from './footer/footer';
import MobileNavbar from './mobile-navbar/mobile-navbar';

import './layout.scss';

// import { useSiteMetadata } from '../hooks/use-site-metadata.ts';

import '../global.scss';

// @ts-ignore
import favicon from '../assets/mark.png';

const Layout = ({ children }: any) => {
  return (
    <Styled.root>
      <Helmet>
        {/* {metatags.map(({ key, value }) => {
          let tag;
          if (key === 'title') {
            tag = <title key={key}>{value}</title>;
          } else if (key === 'canonical') {
            tag = <link key={key} rel={key} href={value} />;
            overrideCanonical = true;
          } else if (tagsWithName.indexOf(key) !== -1) {
            tag = <meta key={key} name={key} content={value} />;
          } else {
            tag = <meta key={key} property={key} content={value} />;
          }

          return tag;
        })} */}

        <html lang="en" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto|Lato&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Navbar />
      <MobileNavbar />
      {children}
      <Footer />
    </Styled.root>
  );
};

export default Layout;
