/* eslint-disable react/prop-types */
import React from 'react';
import Transition from 'react-transition-group/Transition';

import './hamburger-icon.scss';

const topLineStyles = {
  entering: { transform: 'translate3d(0, 4px, 0) rotate(0deg)' }, // menu opening
  entered: { transform: 'translate3d(0, 4px, 0) rotate(-45deg)' }, // menu open
  exiting: { transform: 'translate3d(0, 4px, 0) rotate(0deg)' }, // menu closing
  exited: { transform: 'translate3d(0, 0, 0) rotate(0deg)' } // menu closed
};

const bottomLineStyles = {
  entering: { transform: 'translate3d(0, -3px, 0) rotate(0deg)' }, // menu opening
  entered: { transform: 'translate3d(0px, -3px, 0) rotate(-135deg)' }, // menu open
  exiting: { transform: 'translate3d(0, -3px, 0) rotate(0deg)' }, // menu closing
  exited: { transform: 'translate3d(0, 0, 0) rotate(0deg)' } // menu closed
};

export default function HamburgerIcon({ color, open, onClick }) {
  return (
    <button
      type="button"
      className="hamburger-icon__container"
      onClick={onClick}
    >
      <Transition in={open} timeout={170}>
        {state => (
          <div className={color === 'white' ? 'Lines-white' : 'Lines-black'}>
            <div
              className="Line"
              style={{
                ...topLineStyles[state]
              }}
            />
            <div
              className="Line BottomLine"
              style={{
                ...bottomLineStyles[state]
              }}
            />
          </div>
        )}
      </Transition>
    </button>
  );
}
