/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, ReactNode } from 'react';
import { Flex, Text, Box, Link, Image } from 'rebass';
import MainContainer from '../container/container';
import NavbarItem from './navbar-item';
import { graphql, useStaticQuery } from 'gatsby';
// @ts-ignore
import Logo from '../../assets/logo.png';

interface Props {
  className?: string;
  id?: string;
  children?: ReactNode;
  css?: any;
}

const Nav: FC<Props & JSX.IntrinsicElements['nav']> = ({
  className = '',
  id = '',
  children,
  ...rest
}) => {
  const {
    contentfulMenu: { linkList }
  } = useStaticQuery(
    graphql`
      query {
        contentfulMenu {
          linkList {
            text
            slug
            id
            isDropdownCategory
            dropdownParent {
              id
              text
              slug
            }
          }
        }
      }
    `
  );

  // establish parent -> child relationship for easier iteration. Leave child -> parent in CMS as that is easier to edit when creating new links.
  linkList.map(link => {
    link.children = [];
    if (link.dropdownParent) {
      const parentItem = linkList.find(
        item => item.id === link.dropdownParent.id
      );
      parentItem.children.push(link);
    }
  });

  return (
    <Box
      sx={{
        ' @media screen and (max-width: 63.95em)': {
          display: 'none'
        }
      }}
    >
      <Flex as="nav" alignItems="center">
        <MainContainer
          width="100%"
          justifyContent="space-between"
          sx={{
            paddingTop: '16px !important',
            paddingBottom: '16px !important'
          }}
        >
          <Link my="auto" href="/" display="flex" sx={{ alignItems: 'center' }} data-js-gtm-link-track={`logo-/`}>
            <Image
              src={Logo}
              alt="Knecht Income Tax Service Logo"
              sx={{ height: '50px' }}
            />
          </Link>
          <Flex mt="auto">
            {linkList.map(
              ({
                slug,
                text,
                id,
                isDropdownCategory,
                children,
                dropdownParent
              }) => {
                return (
                  <NavbarItem
                    key={id}
                    dropdownChildren={children}
                    navItem={{ id, text, slug }}
                    isDropdownCategory={isDropdownCategory}
                    dropdownParent={dropdownParent}
                  />
                );
              }
            )}
          </Flex>
        </MainContainer>
      </Flex>
    </Box>
  );
};

export default Nav;
